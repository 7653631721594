import "core-js/modules/es.array.push.js";
import { get } from './../api';
import videojs from "video.js";
import "video.js/dist/video-js.css";
import TWEEN from "@tweenjs/tween.js";

// 动态创建canvas标签  :style="{transform:`scale(${scaleX}) translate(${canvas_width*(scaleX - 1)/(2*scaleX)}px,${canvas_height*(scaleX - 1)/(2*scaleX)}px)`}"
var canvas = null;
var context = null;
const circleColor = 'rgb(0,120,180)';
const lineColor = 'rgb(0,120,180)';
var requestId = null;
var voiceId = null;
var videoPlayer = null;
var videoPostPlayer = null;
var drawCircleId = null;
export default {
  data() {
    return {
      list: [],
      article_index: 0,
      showLeft: true,
      playing: '',
      voiceCount: 2,
      show_step: false,
      show_canvas: false,
      showVideo: false,
      showLeftPic: false,
      vid: 1,
      canvas_width: 538,
      canvas_height: 847,
      drawed: false,
      config: {},
      assetsUrl: window.assetsUrl,
      sidebar_width: '28%',
      width: '',
      height: '',
      clientPos: [// 城市坐标
      ],
      swing: '',
      boatPos: {
        x: 0,
        y: 0
      },
      players: [],
      cityIndex: -1,
      post: {},
      article: {},
      currentLine: [],
      line2: [],
      circleIndex: 0,
      //绘制城市的下标
      drawIndex: 0,
      scaleX: 1
    };
  },
  mounted() {
    document.title = '运河沿岸';
    window.addEventListener('resize', () => {
      this.getWindowInfo();
    });
    this.getWindowInfo();
    this.getHome();
    this.animateVoice();
  },
  unmounted() {
    window.removeEventListener('resize', () => {
      this.getWindowInfo();
    });
    if (requestId) {
      console.log('cancelAnimationFrame', requestId);
      window.cancelAnimationFrame(requestId);
    }
    clearTimeout(drawCircleId);
    clearInterval(voiceId);
  },
  methods: {
    animateVoice() {
      voiceId = setInterval(() => {
        if (this.voiceCount > 100) {
          this.voiceCount = 0;
        } else {
          this.voiceCount++;
        }
      }, 300);
    },
    back() {
      this.$router.push('/');
    },
    playAudio(id = 0, next = false) {
      console.log('playAudio', this.playing, id);
      if (this.playing == 'voice' && !next) {
        this.stopAudio();
        this.playing = '';
        return false;
      }
      this.playing = 'voice';
      console.log('playAudio real', id);
      this.$refs.audio.forEach(audio => {
        console.log(audio.id);
        if (audio.id == id) {
          audio.currentTime = 0;
          audio.play();
          console.log('playAudio', audio);
        } else {
          audio.pause();
        }
      });
    },
    stopAudio() {
      if (this.$refs.audio) {
        this.showLeft = true;
        this.$refs.audio.forEach(audio => {
          audio.pause();
        });
      }
    },
    onCanPlay() {},
    onPause(id) {
      console.log('onPauseAudio', this.playing, id);
      if (this.playing == 'voice') {
        console.log('playNext');
        this.playing == '';
        this.showLeft = true;
        setTimeout(() => {
          this.playAudio(id + 1, true);
        }, 100);
      }
    },
    onPlay() {
      this.showLeft = false;
      if (videoPlayer) {
        videoPlayer.pause();
      }
      if (videoPostPlayer) {
        videoPostPlayer.pause();
      }
    },
    onVideoPlay() {
      console.log('onVideoPlay');
      this.playing = 'video';
      this.showLeft = true;
      this.stopAudio();
    },
    myTween(toObj) {
      let vm = this;
      const animate = function () {
        if (TWEEN.update()) {
          requestAnimationFrame(animate);
        }
      };
      let duration = 10 * Math.sqrt(Math.pow(Math.abs(this.boatPos.x - toObj.x), 2) + Math.pow(Math.abs(this.boatPos.y - toObj.y), 2));
      console.log(duration);
      new TWEEN.Tween(this.boatPos).to(toObj, duration).onUpdate(object => {
        vm.boatPos = object;
      }).start().onComplete(() => {
        vm.boatPos = toObj;
        vm.swing = '';
        setTimeout(() => {
          vm.swing = 'animate__swing';
        });
      });
      animate();
    },
    changeArticle(idx) {
      this.article = {
        video_url: ''
      };
      this.article_index = idx;
      this.article = this.list[this.article_index];
      if (this.article.video_url) {
        setTimeout(() => {
          if (!videoPlayer) {
            videoPlayer = videojs(this.$refs.videoArticle);
            videoPlayer.load();
            videoPlayer.play();
          } else {
            videoPlayer.pause();
            videoPlayer.reset();
            videoPlayer.src([{
              src: this.article.video_url
            }]);
            videoPlayer.load();
            videoPlayer.play();
          }
        }, 200);
      } else {
        videoPlayer = null;
      }
    },
    getWindowInfo() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
      let height = this.height - 80;
      this.scaleX = height / this.canvas_height;
      console.log('getWindowInfo', this.scaleX);
      if (this.drawed) {
        console.log('repaint river');
        this.circleIndex = 0;
        if (requestId) {
          console.log('cancelAnimationFrame', requestId);
          window.cancelAnimationFrame(requestId);
        }
        clearTimeout(drawCircleId);
        this.drawRiver();
      }
    },
    clickCanvas(e) {
      console.log(e);
      const {
        layerX,
        layerY
      } = e;
      console.log(layerX, layerY);
      let clientPos = this.clientPos;
      let minIndex = -1;
      let minLen = 10000;
      clientPos.forEach((item, index) => {
        let tmp = Math.abs(item.x * this.scaleX - layerX) + Math.abs(item.y * this.scaleX - layerY);
        if (minLen > tmp) {
          minLen = tmp;
          minIndex = index;
        }
      });
      console.log(minIndex, minLen);
      if (minLen < 60 * this.scaleX) {
        this.show_step = true;
        if (this.cityIndex != minIndex) {
          this.article_index = 0;
          this.cityIndex = minIndex;
          let firstCity = this.clientPos[0];
          let city = this.clientPos[this.cityIndex];
          if (this.boatPos.x == 0) {
            this.boatPos = {
              x: firstCity.x,
              y: firstCity.y
            };
          }
          this.swing = '';
          setTimeout(() => {
            this.swing = 'animate__swing';
          }, 200);
          setTimeout(() => {
            this.myTween({
              x: city.x,
              y: city.y
            });
          }, 600);
          this.getArticles();
        }
      } else {
        videoPlayer = null;
        this.cityIndex = -1;
        this.show_step = false;
        setTimeout(() => {
          videoPostPlayer = videojs(this.$refs.video);
        }, 20);
      }
    },
    toUrl(idx) {
      let route = this.urls[idx];
      this.$router.push(route);
    },
    loadPic() {
      this.showLeftPic = true;
      console.log('showLeftPic');
      this.drawRiver();
    },
    getHome() {
      get('city').then(res => {
        console.log(res);
        this.clientPos = res.data.list;
        this.post = res.data.post;
        this.showVideo = true;
        this.drawed = true;
        console.log('showVideo');
        setTimeout(() => {
          videoPostPlayer = videojs(this.$refs.video);
        }, 20);
        this.drawRiver();
      });
    },
    getArticles() {
      let cat = this.clientPos[this.cityIndex].id;
      console.log('cat', cat);
      get('cityArticles', {
        cat
      }).then(res => {
        console.log(res);
        this.list = res.data;
        if (this.list.length > 0) {
          this.changeArticle(0);
        } else {
          videoPlayer = null;
          let city = this.clientPos[this.cityIndex].name;
          this.article = {
            content: `城市${city}尚未添加内容!`
          };
        }
      });
    },
    drawRiver() {
      if (!this.showLeftPic || !this.showVideo) {
        return false;
      }
      console.log('drawRiver');
      canvas = document.getElementById("canvas");
      if (!canvas) {
        return false;
      }
      context = canvas.getContext("2d");
      // 设置canvas画布宽度和高度
      canvas.width = this.canvas_width * this.scaleX;
      canvas.height = this.canvas_height * this.scaleX;
      console.log(canvas.width, canvas.height);
      // 用context.rect方法绘制矩形
      context.rect(0, 0, canvas.width, canvas.height);
      // 设置字体颜色
      context.fillStyle = "#fff";
      // 渲染
      context.fill();
      context.drawImage(document.getElementById('river_pic'), 0, 0, canvas.width, canvas.height);
      this.show_canvas = true;
      drawCircleId = setTimeout(() => {
        this.drawCircle();
      }, 2000);
    },
    drawCircle() {
      if (this.circleIndex > this.clientPos.length - 1) {
        console.log('draw cicrcke end');
        return false;
      }
      let pos = this.clientPos[this.circleIndex];
      context.beginPath();
      context.strokeStyle = circleColor;
      context.lineWidth = 3;
      context.arc(pos.x * this.scaleX, pos.y * this.scaleX, 8, 0, 2 * Math.PI);
      context.stroke();
      context.fill();
      this.circleIndex++;
      if (pos.line) {
        this.drawLine(pos.line);
      } else {
        requestId = requestAnimationFrame(() => {
          this.drawCircle();
        });
      }
    },
    drawLine(line_arr) {
      this.currentLine = line_arr;
      this.drawIndex = 0;
      // console.log('requestId',requestId);
      requestId = requestAnimationFrame(() => {
        this.animate();
      });
    },
    animate() {
      let from = this.currentLine[this.drawIndex];
      this.drawIndex++;
      let to = this.currentLine[this.drawIndex];
      context.beginPath(); //开启一个新路径，与之前的断开联系
      context.lineWidth = 3;
      context.strokeStyle = lineColor;
      context.moveTo(from.x * this.scaleX, from.y * this.scaleX);
      context.lineTo(to.x * this.scaleX, to.y * this.scaleX);
      context.closePath();
      context.stroke();
      if (this.drawIndex < this.currentLine.length - 2) {
        requestId = requestAnimationFrame(() => {
          this.animate();
        });
      } else {
        requestId = requestAnimationFrame(() => {
          this.drawCircle();
        });
      }
    }
  }
};